// FOR NON-RESPONSIVE PIECES ONLY

.margin-none {
  margin: 0;
}

// MARGIN-TINY

.margin-tiny {
  margin: 0.25rem;
}
.margin-top-bottom-tiny {
  margin: 0.25rem 0rem;
}
.margin-left-right-tiny {
  margin: 0rem 0.25rem;
}
.margin-top-tiny {
  margin-top: 0.25rem;
}
.margin-right-tiny {
  margin-right: 0.25rem;
}
.margin-bottom-tiny {
  margin-bottom: 0.25rem;
}
.margin-left-tiny {
  margin-left: 0.25rem;
}

// MARGIN-SMALL

.margin-small {
  margin: 1rem;
}
.margin-top-bottom-small {
  margin: 1rem 0rem;
}
.margin-left-right-small {
  margin: 0rem 1rem;
}
.margin-top-small {
  margin-top: 1rem;
}
.margin-right-small {
  margin-right: 1rem;
}
.margin-bottom-small {
  margin-bottom: 1rem;
}
.margin-left-small {
  margin-left: 1rem;
}

// MARGIN-MEDIUM

.margin-medium {
  margin: 1.5rem;
}
.margin-top-bottom-medium {
  margin: 1.5rem 0rem;
}
.margin-left-right-medium {
  margin: 0rem 1.5rem;
}
.margin-top-medium {
  margin-top: 1.5rem;
}
.margin-right-medium {
  margin-right: 1.5rem;
}
.margin-bottom-medium {
  margin-bottom: 1.5rem;
}
.margin-left-medium {
  margin-left: 1.5rem;
}

// MARGIN-LARGE

.margin-large {
  margin: 2rem;
}
.margin-top-bottom-large {
  margin: 2rem 0rem;
}
.margin-left-right-large {
  margin: 0rem 2rem;
}
.margin-top-large {
  margin-top: 2rem;
}
.margin-right-large {
  margin-right: 2rem;
}
.margin-bottom-large {
  margin-bottom: 2rem;
}
.margin-left-large {
  margin-left: 2rem;
}

.medium-large-margins {
  button,
  div {
    margin: 1.75rem;
    .text-message {
      margin: 0.5rem;
    }
  }
}

// SPECIAL

.padding-none {
  padding: 0rem !important;
}

.padding-huge {
  padding: 4rem;
}

.padding-medium {
  padding: 1.5rem;
}

.padding-large {
  padding: 2rem;
}

// PADDING-TINY

.padding-tiny {
  padding: 0.25rem;
}
.padding-top-bottom-tiny {
  padding: 0.25rem 0rem;
}
.padding-left-right-tiny {
  padding: 0rem 0.25rem;
}
.padding-top-tiny {
  padding-top: 0.25rem;
}
.padding-right-tiny {
  padding-right: 0.25rem;
}
.padding-bottom-tiny {
  padding-bottom: 0.25rem;
}
.padding-left-tiny {
  padding-left: 0.25rem;
}

// PADDING-SMALL

.padding-small {
  padding: 1rem;
}
.padding-top-bottom-small {
  padding: 1rem 0rem;
}
.padding-left-right-small {
  padding: 0rem 1rem;
}
.padding-top-small {
  padding-top: 1rem;
}
.padding-right-small {
  padding-right: 1rem;
}
.padding-bottom-small {
  padding-bottom: 1rem;
}
.padding-left-small {
  padding-left: 1rem;
}
