.button-normal {
  @include flex-row-center-center;
  @include button(white, $bright-blue-hex);

  &-disabled {
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      cursor: default;
      background-color: white;
      color: $bright-blue-hex;
    }
  }

  &-smaller {
    padding: 0.5rem;
  }

  &-smallest {
    padding: 2px;
  }
}

.button-elevated {
  @include flex-row-center-center;
  @include button(white, $medium-green-hex);
  &-disabled {
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      cursor: default;
      background-color: white;
      color: $medium-green-hex;
    }
  }

  &-smaller {
    padding: 0.5rem;
  }
}

.button-negative {
  @include flex-row-center-center;
  @include button(white, $bright-red-hex);
  &-disabled {
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      cursor: default;
      background-color: white;
      color: $bright-red-hex;
    }
  }

  &-smaller {
    padding: 0.5rem;
  }
}

.button-neutral {
  @include flex-row-center-center;
  @include button(white, $medium-amber-hex);

  &-disabled {
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      cursor: default;
      background-color: white;
      color: $medium-amber-hex;
    }
  }

  &-smaller {
    padding: 0.5rem;
  }
}

.button-full-row {
  @include flex-row-center-center;
  background-color: white;
  width: 100%;
}
