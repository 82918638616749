.legend {
  @include flex-row-center-evenly;

  color: $dark-blue-hex;

  &-box {
    height: 1rem;
    width: 1rem;

    box-shadow: 0rem 0rem 0.5rem white;

    &-1 {
      background-color: $medium-green-hex;
    }

    &-2 {
      background-color: $dark-blue-hex;
    }

    &-3 {
      background-color: $dark-amber-hex;
    }

    &-4 {
      background-color: $dark-red-hex;
    }
  }
}
