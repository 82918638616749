*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  /* This is the root font size for use with the "rem" unit */
  @media (max-width: 1920px) {
    font-size: 62.5%; // 1rem = 10px
  }

  @media (max-width: 1280px) {
    font-size: 50%; // 1rem = 8px
  }
}

body {
  box-sizing: border-box;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100vw;

  @media (max-width: 1280px) {
    height: unset;
  }
}

.body-blue {
  background-color: $transparent-bright-blue-25;
}

.list {
  list-style-type: none;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.cursor-none {
  cursor: none;
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

.consent-form-parent {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.consent-form {
  margin: 2rem;
  width: 50%;
  min-width: 300px;
}

#consent-checked {
  min-width: 25px;
  margin-right: 2rem;
}

.consent-main {
  display: flex;
  margin-top: 4rem;
}

.underscore {
  border-bottom: 2px solid black;
  margin-top: 4rem;
}
