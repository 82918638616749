.module-checkbox-container {
  @include flex-row-center-between;
  width: 100%;

  &-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.module {
  @include flex-row-center-between;
  border: 2px solid $dark-blue-hex;
  background-color: $off-white-hex;
  cursor: pointer;
  margin: 0.5rem;
  padding: 0.25rem;

  * {
    @include tiny-text;
  }

  &:hover {
    background-color: white;
    border: 2px solid $medium-green-hex;
  }

  &-data {
    overflow: hidden;
  }

  &-main {
    @include flex-column-start-between;
    @include center-text;
    flex-grow: 1;
    margin-right: 1rem;
    max-width: 70%;

    &-right {
      margin-left: 1rem;
      margin-right: 0rem;
    }
  }

  &-bottom-margin {
    margin: 0rem 0rem 1rem 0rem;
  }

  &-section {
    @include flex-column-center-between;
    padding: 0.5rem;
    flex-grow: 1;
    max-width: 33%;

    * {
      padding: 0.25rem;
    }

    &-middle {
      @include flex-row-center-center;
      height: 2.5rem;
    }

    &-normal {
      background-color: $dark-blue-hex;
    }

    &-dark-amber {
      background-color: $dark-amber-hex;
    }

    &-elevated {
      background-color: $medium-green-hex;
    }

    &-negative {
      background-color: $dark-red-hex;
    }

    &-urgent {
      background-color: $bright-red-hex;
      color: black;
    }

    &-outline {
      border: 2px solid $dark-blue-green-hex;
    }

    &-vehicle-left {
      background-color: $dark-amber-hex;
    }

    &-vehicle-right {
      max-width: unset;
    }
  }

  &-full-width {
    width: 100%;
  }

  &-checkbox {
    flex-grow: 1;
    max-width: 80%;
  }

  &-height-unset {
    height: unset;
    flex: unset;
  }

  &-with-header {
    @include flex-column-center-between;
    padding: 0rem;

    &-padded-body {
      padding: 0rem 1rem 1rem 1rem;
    }
  }

  &-header {
    @include padding-tiny;
    @include margin-bottom-tiny;
    background-color: $dark-blue-green-hex;
    color: white;
    text-align: center;
    width: 100%;
  }

  &-body {
    @include flex-row-center-between;
    @include padding-tiny;
  }

  &-special {
    background-color: $transparent-bright-green-10;
    &-hot {
      border: 2px solid $dark-red-hex;
    }
  }

  &-positive {
    background-color: $transparent-bright-green-10;
  }

  &-neutral {
    background-color: $transparent-bright-amber-10;
  }

  &-negative {
    background-color: $transparent-bright-red-10;
  }

  &-vehicle {
    border: 2px solid $dark-amber-hex;
  }
}

.hide-overflow {
  overflow: hidden;
}
