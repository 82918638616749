/* Color Theme Swatches in Hex */
$bright-blue-hex: #0da7ff;
$bright-red-hex: #ff1929;
$bright-blue-green-hex: #00ffd0;
$bright-amber-hex: #ff9c19;
$bright-green-hex: #0dff45;
$medium-blue-hex: #0a7dbf;
$medium-red-hex: #bf131f;
$medium-blue-green-hex: #00bf9c;
$medium-amber-hex: #bf7513;
$medium-green-hex: #0abf34;
$dark-blue-hex: #086499;
$dark-red-hex: #990f19;
$dark-blue-green-hex: #00997d;
$dark-amber-hex: #995e0f;
$dark-green-hex: #08992b;

$off-white-hex: #f9f9f9;
$light-grey-hex: #d3d3d3;
$medium-grey-hex: #787878;
$dark-grey-hex: #1f1f1f;

$transparent-black-80: rgba(0, 0, 0, 0.8);
$transparent-black-10: rgba(0, 0, 0, 0.1);
$transparent-bright-blue-10: rgba(13, 167, 255, 0.1);
$transparent-bright-blue-25: rgba(13, 167, 255, 0.25);
$transparent-bright-green-10: rgba(13, 255, 69, 0.1);
$transparent-bright-amber-10: rgba(255, 156, 25, 0.1);
$transparent-bright-red-10: rgba(255, 25, 41, 0.1);
