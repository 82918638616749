.full-page-center {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  &-with-navbar {
    height: 90vh;
  }
}

.logo-circle {
  border: 0.2rem solid white;
  background-color: white;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;

  &-red {
    background-color: $bright-red-hex;
  }

  &-amber {
    background-color: $bright-amber-hex;
  }

  &-green {
    background-color: $bright-green-hex;
  }
}
