.icon {
  height: 10rem;
  width: 10rem;

  &-add-customer {
    fill: $medium-blue-green-hex;
  }

  &-my-customers {
    fill: $dark-blue-hex;
  }

  &-actions {
    fill: $medium-green-hex;
  }

  &-performance {
    fill: $medium-amber-hex;
  }

  &-sales-log {
    fill: $dark-green-hex;
  }

  &-inventory {
    fill: $dark-blue-green-hex;
  }
}

.icon-small {
  height: 5rem;
  width: 5rem;

  &-back {
    fill: white;
    stroke: white;

    &:hover {
      stroke: $bright-red-hex;
    }
  }
}

.icon-smaller {
  height: 4rem;
  width: 4rem;
}

.icon-tiny {
  height: 3rem;
  width: 3rem;
  fill: $bright-amber-hex;
}
