.grid {
  @include flex-row-center-evenly;
  flex-flow: wrap;
}

.grid-row {
  @include flex-row-center-center;
  flex-wrap: wrap;
  width: 100%;
}

.grid-item {
  @include flex-column-center-evenly;
  @include margin-small;
  @include padding-small;
  border-radius: 10px;
  border: 2px solid $dark-blue-hex;
  height: 300px;
  width: 350px;

  @media (max-width: 1280px) {
    height: 275px;
    width: 320px;
    @include margin-tiny;
  }

  &:hover {
    background-color: $transparent-bright-blue-10;
  }

  &-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &-no-click {
    &:hover {
      background-color: transparent;
    }
  }

  &-short {
    @include flex-column-center-evenly;
    height: 125px;
    width: unset;

    @media (max-width: 1280px) {
      height: 90px;
    }

    @media (max-width: 900px) {
      height: unset;
      overflow: hidden;
      width: 100%;
    }

    @media (max-width: 600px) {
      max-width: unset;
      width: 350px;
    }
  }

  &-row {
    @include flex-row-center-evenly;
    flex-wrap: wrap;

    @media (max-width: 1280px) {
      height: 7vh;
      @include margin-tiny;
    }

    @media (max-width: 1280px) {
      height: unset;
      @include margin-tiny;
    }
  }

  &-titled {
    @include flex-column-center-between;
    padding: 0px;
  }

  &-heading {
    @include flex-row-center-center;
    background-color: $dark-blue-hex;
    border-radius: 10px 10px 0px 0px;
    height: 17.5%;
    width: 100%;

    &-elevated {
      background-color: $dark-green-hex;
    }

    &-negative {
      background-color: $dark-red-hex;
    }

    &-neutral {
      background-color: $dark-amber-hex;
    }
  }

  &-body {
    @include flex-column-center-evenly;
    @include padding-small;
    height: 82.5%;
    width: 100%;

    &-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &-section {
      @include padding-small;
      @include margin-bottom-small;
      background-color: white;
      border: 1px solid $medium-grey-hex;
      border-radius: 10px;
      max-height: 70%;
      max-width: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: scroll;

      &-special {
        @media (max-width: 600px) {
          max-height: 10rem;
        }
      }
    }

    &-column-three-fifths {
      @include flex-column-center-evenly;
      width: 60%;
    }
  }

  &-image {
    background-size: cover;
    overflow: hidden;
    border-radius: 10px;
    &-customer-search {
      background-image: url("../util/customer-search.png");
    }

    &-call-center {
      background-image: url("../util/call-girl.png");
    }

    &-exponential {
      background-image: url("../util/exponential.png");
    }
  }
}

.info-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.grey-container {
  background-color: $transparent-black-10;
  border-top: 2px solid $dark-blue-hex;
  border-bottom: 2px solid $dark-blue-hex;
  width: 100%;
}

.ten-grid-item {
  @include flex-column-center-start;
  @include margin-small;
  background-color: white;
  border-radius: 10px;
  border: 2px solid $dark-blue-hex;
  height: 300px;
  padding: 0px;
  cursor: default;
  width: 250px;

  &:hover {
    background-color: $transparent-bright-blue-10;
  }

  &-body {
    @include flex-column-center-evenly;
    height: 89.9%;
    width: 100%;
  }

  &-heading {
    @include flex-row-center-center;
    background-color: $dark-blue-hex;
    border-radius: 10px 10px 0px 0px;
    height: 10%;
    width: 100%;
  }

  &-row {
    @include flex-row-center-evenly;
    margin: 0.25rem 0rem;
    width: 100%;

    &-narrow {
      width: 50%;
    }

    &-avatar {
      height: 3rem;
    }

    * {
      @include flex-row-center-center;
    }
  }

  &-short {
    @include flex-column-center-center;
    cursor: pointer;
    height: 150px;
  }

  &-green {
    border: 2px solid $dark-blue-green-hex;
  }

  &-red {
    border: 2px solid $dark-red-hex;
  }

  &-image {
    background-size: cover;
    overflow: hidden;
    border-radius: 10px;

    &-uncluttered {
      background-image: url("../util/uncluttered-look.png");
    }

    &-one-interface {
      background-image: url("../util/one-interface.png");
    }

    &-salesperson-on-phone {
      background-image: url("../util/salesperson-on-phone.jpg");
    }
  }
}

.infobar-row {
  @include flex-row-center-evenly;

  &-top {
    @media (max-width: 900px) {
      @include margin-bottom-small;
    }
  }
}

.infobar-column {
  @include flex-column-center-evenly;
  @include margin-left-right-small;
  height: 100%;

  &-first {
    @media (max-width: 900px) {
      @include margin-bottom-small;
    }
  }
}

.section-row {
  @include flex-row-center-between;
  @include margin-bottom-small;
  width: 100%;
  &-info {
    @include flex-column-start-center;
    @include margin-right-small;
    overflow: hidden;
    width: 25%;

    &-row {
      @include margin-bottom-tiny;

      &-bottom {
        margin-bottom: 0;
      }
    }
  }

  &-content {
    overflow: hidden;
    width: 75%;
  }
}
