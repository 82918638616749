.main-header-background {
  background-color: $off-white-hex;
  background-image: url("../util/zen-min.jpg");
  background-size: cover;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  opacity: 25%;
}

.background-white {
  background-color: white;
  border-radius: 10px;
  border: 2px solid $medium-blue-hex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.border-section {
  height: 10vh;
}

.iphone-graphic {
  background-size: contain;
  background-image: url("../util/iphone-display.png");
  background-repeat: no-repeat;
  background-position: center;
  min-width: 50vw;

  @media (max-width: 900px) {
    min-width: 90vw;
    max-width: 90vw;
  }
}

.homepage-section {
  padding: 0rem 2rem;
  @include flex-row-center-center;

  @media (max-width: 900px) {
    padding: 0rem;
    margin-top: 2rem;
    @include flex-column-center-center;
  }

  &-half {
    min-width: 340px;
    width: 50%;

    @media (max-width: 900px) {
      width: 100%;
      max-width: 340px;
    }
  }

  &-offset {
    background-color: $off-white-hex;
  }
}

.section-subheading {
  width: 75%;
  line-height: 3rem;

  @media (max-width: 900px) {
    width: 320px;
  }
}

.performance-section {
  border-radius: 10px;
  border: 2px solid $medium-amber-hex;
  padding: 2rem;
  margin: 2rem 2rem 2rem 2rem;
  min-width: 320px;
  max-width: 640px;
}

.desk-log-section {
  border-radius: 10px;
  border: 2px solid $dark-green-hex;
  padding: 2rem;
  margin: 2rem 2rem 2rem 2rem;
  min-width: 320px;
  max-width: 640px;
}

.performance-image {
  background-size: contain;
  border-radius: 10px;
  border: 2px solid $medium-amber-hex;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 300px;
  background-repeat: no-repeat;

  &-screen {
    background-image: url("../util/performance-screen.png");
  }

  &-stats {
    background-image: url("../util/performance-stats.png");
  }

  &-duplicate {
    background-image: url("../util/duplicate-detection.png");
  }

  &-color {
    background-image: url("../util/color-coded.png");
  }
}

.desk-log-image {
  background-size: contain;
  border-radius: 10px;
  border: 2px solid $dark-green-hex;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  height: 300px;
  background-repeat: no-repeat;

  &-mobile {
    background-image: url("../util/mobile-manager.png");
  }
  &-trends {
    background-image: url("../util/sold-tally.png");
  }
  &-notes {
    background-image: url("../util/notes.png");
  }
  &-inventory {
    background-image: url("../util/inventory-search.png");
  }
}

.public-contact-container {
  min-width: 320px;
  height: 320px;
  border: 2px solid $medium-blue-green-hex;
  border-radius: 10px;
  padding: 1.5rem;

  &-smaller {
    padding: 1rem;
    height: 4rem;
    overflow: hidden;
  }
}

.early-adopter-subheading {
  line-height: 3rem;
  width: 640px;

  @media (max-width: 900px) {
    width: 320px;
  }
}
