// USE MIXINS FOR RESPONSIVE PIECES

//
//
// RANDOM MIXINS
//
//

@mixin button($background-color, $color) {
  background-color: $background-color;
  border: 3px solid $color;
  border-radius: 1rem;
  color: $color;
  cursor: pointer;
  padding: 1rem;

  &:hover {
    background-color: $color;
    color: $background-color;
  }
}

@mixin tiny-text {
  font-family: Fira Sans;
  font-size: 1.4rem;
  line-height: 1.65rem;
}

@mixin small-text {
  font-family: Fira Sans;
  font-size: 1.6rem;
  line-height: 1.85rem;
}

@mixin center-text {
  @include flex-column-center-center;

  * {
    text-align: center;
  }
}

//
//
// FLEX MIXINS
//
//

// FLEX

//// COLUMN

////// COLUMN ALIGN START

@mixin flex-column-start-start {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@mixin flex-column-start-center {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin flex-column-start-around {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@mixin flex-column-start-between {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin flex-column-start-evenly {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@mixin flex-column-start-end {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

////// COLUMN ALIGN CENTER

@mixin flex-column-center-start {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@mixin flex-column-center-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin flex-column-center-around {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@mixin flex-column-center-between {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin flex-column-center-evenly {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@mixin flex-column-center-end {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

////// COLUMN ALIGN END

@mixin flex-column-end-start {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@mixin flex-column-end-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin flex-column-end-around {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@mixin flex-column-end-between {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin flex-column-end-evenly {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@mixin flex-column-end-end {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

//// ROW

////// ROW ALIGN START

@mixin flex-row-start-start {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@mixin flex-row-start-center {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@mixin flex-row-start-around {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@mixin flex-row-start-between {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@mixin flex-row-start-evenly {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@mixin flex-row-start-end {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

////// ROW ALIGN CENTER

@mixin flex-row-center-start {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@mixin flex-row-center-center {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@mixin flex-row-center-around {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@mixin flex-row-center-between {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@mixin flex-row-center-evenly {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@mixin flex-row-center-end {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

////// ROW ALIGN END

@mixin flex-row-end-start {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

@mixin flex-row-end-center {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@mixin flex-row-end-around {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@mixin flex-row-end-between {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@mixin flex-row-end-evenly {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

@mixin flex-row-end-end {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

//
//
// SPACERS
//
//

// MARGIN-SPECIAL

@mixin margin-auto {
  margin-left: auto;
  margin-right: auto;
}

// MARGIN-TINY

@mixin margin-tiny {
  margin: 0.25rem;
}
@mixin margin-top-bottom-tiny {
  margin: 0.25rem 0rem;
}
@mixin margin-left-right-tiny {
  margin: 0rem 0.25rem;
}
@mixin margin-top-tiny {
  margin-top: 0.25rem;
}
@mixin margin-right-tiny {
  margin-right: 0.25rem;
}
@mixin margin-bottom-tiny {
  margin-bottom: 0.25rem;
}
@mixin margin-left-tiny {
  margin-left: 0.25rem;
}

// MARGIN-SMALL

@mixin margin-small {
  margin: 1rem;
}
@mixin margin-top-bottom-small {
  margin: 1rem 0rem;
}
@mixin margin-left-right-small {
  margin: 0rem 1rem;
}
@mixin margin-top-small {
  margin-top: 1rem;
}
@mixin margin-right-small {
  margin-right: 1rem;
}
@mixin margin-bottom-small {
  margin-bottom: 1rem;
}
@mixin margin-left-small {
  margin-left: 1rem;
}

// MARGIN-MEDIUM

@mixin margin-medium {
  margin: 1.5rem;
}
@mixin margin-top-bottom-medium {
  margin: 1.5rem 0rem;
}
@mixin margin-left-right-medium {
  margin: 0rem 1.5rem;
}
@mixin margin-top-medium {
  margin-top: 1.5rem;
}
@mixin margin-right-medium {
  margin-right: 1.5rem;
}
@mixin margin-bottom-medium {
  margin-bottom: 1.5rem;
}
@mixin margin-left-medium {
  margin-left: 1.5rem;
}

// MARGIN-LARGE

@mixin margin-large {
  margin: 2rem;
}
@mixin margin-top-bottom-large {
  margin: 2rem 0rem;
}
@mixin margin-left-right-large {
  margin: 0rem 2rem;
}
@mixin margin-top-large {
  margin-top: 2rem;
}
@mixin margin-right-large {
  margin-right: 2rem;
}
@mixin margin-bottom-large {
  margin-bottom: 2rem;
}
@mixin margin-left-large {
  margin-left: 2rem;
}

// PADDING-TINY

@mixin padding-tiny {
  padding: 0.25rem;
}
@mixin padding-top-bottom-tiny {
  padding: 0.25rem 0rem;
}
@mixin padding-left-right-tiny {
  padding: 0rem 0.25rem;
}
@mixin padding-top-tiny {
  padding-top: 0.25rem;
}
@mixin padding-right-tiny {
  padding-right: 0.25rem;
}
@mixin padding-bottom-tiny {
  padding-bottom: 0.25rem;
}
@mixin padding-left-tiny {
  padding-left: 0.25rem;
}

// PADDING-SMALL

@mixin padding-small {
  padding: 1rem;
}
@mixin padding-top-bottom-small {
  padding: 1rem 0rem;
}
@mixin padding-left-right-small {
  padding: 0rem 1rem;
}
@mixin padding-top-small {
  padding-top: 1rem;
}
@mixin padding-right-small {
  padding-right: 1rem;
}
@mixin padding-bottom-small {
  padding-bottom: 1rem;
}
@mixin padding-left-small {
  padding-left: 1rem;
}

// PADDING-MEDIUM

@mixin padding-medium {
  padding: 1.5rem;
}
@mixin padding-top-bottom-medium {
  padding: 1.5rem 0rem;
}
@mixin padding-left-right-medium {
  padding: 0rem 1.5rem;
}
@mixin padding-top-medium {
  padding-top: 1.5rem;
}
@mixin padding-right-medium {
  padding-right: 1.5rem;
}
@mixin padding-bottom-medium {
  padding-bottom: 1.5rem;
}
@mixin padding-left-medium {
  padding-left: 1.5rem;
}

// PADDING-LARGE

@mixin padding-large {
  padding: 2rem;
}
@mixin padding-top-bottom-large {
  padding: 2rem 0rem;
}
@mixin padding-left-right-large {
  padding: 0rem 2rem;
}
@mixin padding-top-large {
  padding-top: 2rem;
}
@mixin padding-right-large {
  padding-right: 2rem;
}
@mixin padding-bottom-large {
  padding-bottom: 2rem;
}
@mixin padding-left-large {
  padding-left: 2rem;
}
