.medium-red-text {
  color: $medium-red-hex;
}

.dark-blue-text {
  color: $dark-blue-hex;
}

.medium-blue-text {
  color: $medium-blue-hex;
}

.bright-blue-text {
  color: $bright-blue-hex;
}

.medium-blue-green-text {
  color: $medium-blue-green-hex;
}

.dark-blue-green-text {
  color: $dark-blue-green-hex;
}

.medium-green-text {
  color: $medium-green-hex;
}

.medium-grey-text {
  color: $medium-grey-hex;
}

.light-grey-text {
  color: $light-grey-hex;
}

.white-text {
  color: white;
}

.bright-green-text {
  color: $bright-green-hex;
}

.medium-amber-text {
  color: $medium-amber-hex;
}

.bright-red-text {
  color: $bright-red-hex;
}

.dark-red-text {
  color: $dark-red-hex;
}

.dark-green-text {
  color: $dark-green-hex;
}

.dark-grey-text {
  color: $dark-grey-hex;
}

.dark-amber-text {
  color: $dark-amber-hex;
}

.fifty-opacity {
  opacity: 0.5;
}

.full-opacity {
  opacity: 1;
}

.dark-amber-white {
  background-color: $dark-amber-hex;
  color: white;

  &:hover {
    background-color: $medium-amber-hex;
  }
}

.dark-blue-white {
  background-color: $dark-blue-hex;
  color: white;

  &:hover {
    background-color: $dark-blue-hex;
  }
}

.dark-green-white {
  background-color: $dark-green-hex;
  color: white;

  &:hover {
    background-color: $medium-blue-hex;
  }
}

.blue-green-underline {
  border-bottom: 2px solid $dark-blue-green-hex;
  padding-bottom: 5px;
}

.orange-red {
  background-color: orangered;
}
