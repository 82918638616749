@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: "Fira Sans";
  src: url("../fonts/FiraSans/FiraSans-Regular.ttf");
}

@font-face {
  font-family: "Zwodrei";
  src: url("../fonts/Zwodrei/zwodrei Bold.ttf");
}

@font-face {
  font-family: "ZenTokyoZoo";
  src: url("../fonts/ZenTokyoZoo/ZenTokyoZoo-Regular.ttf");
}

@font-face {
  font-family: "Syke";
  src: url("../fonts/Syke/Syke-Regular.ttf");
}

a {
  text-decoration: none;
}

.avatar {
  border-radius: 50%;
  cursor: pointer;
  height: 75px;
  width: 75px;
}

.avatar-medium-small {
  border-radius: 50%;
  height: 45px;
  width: 45px;
}

.avatar-smaller {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.main-title {
  font-family: ZenTokyoZoo;
  font-size: 5rem;
  // font-style: italic;
  font-weight: 400;

  &-smaller {
    font-size: 4rem;
  }

  &-glow {
    &:hover {
      text-shadow: 0rem 0rem 3rem $bright-green-hex;
    }
  }
}

.large-text {
  font-family: Fira Sans;
  font-size: 3rem;
  line-height: 3.25rem;
}

.medium-large-text {
  font-family: Fira Sans;
  font-size: 2.5rem;
  line-height: 2.75rem;
}

.medium-text {
  font-family: Fira Sans;
  font-size: 2rem !important;
  line-height: 2.25rem;
}

.small-medium-text {
  font-family: Fira Sans;
  font-size: 1.8rem;
  line-height: 2.05rem;
}

.small-text {
  font-family: Fira Sans;
  font-size: 1.6rem;
  line-height: 1.85rem;
}

.smaller-text {
  font-family: Fira Sans;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.tiny-text {
  font-family: Fira Sans;
  font-size: 1.4rem;
  line-height: 1.65rem;
}

.spaced-letters {
  letter-spacing: 0.15rem;
}

.logo-small {
  margin: 0rem 1.5rem;
  width: 5rem;
  @media (max-width: 600px) {
    margin: 0rem 1.5rem 0rem 0rem;
  }
}

.italic-text {
  font-style: italic;
}

.center-text {
  text-align: center;
}

.custom-radio {
  @include flex-row-center-center;
  position: relative;

  & input {
    opacity: 0;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;

    &:checked + .radio-button {
      background: radial-gradient($dark-blue-green-hex 50%, white 50%);
    }
  }
}

.radio-button {
  border: 2px solid $dark-blue-green-hex;
  border-radius: 50%;
  background-color: white;
  height: 2.5rem;
  width: 2.5rem;
}

.custom-checkbox {
  @include flex-row-center-center;
  position: relative;

  & input {
    opacity: 0;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;

    &:checked + .checkbox::after {
      content: "\2714";
    }
  }
}

.checkbox {
  border: 2px solid $dark-blue-green-hex;
  background-color: white;
  color: $dark-blue-green-hex;
  height: 2.5rem;
  min-height: 2.5rem;
  min-width: 2.5rem;
  width: 2.5rem;

  &-module {
    margin: 1rem 0rem 1rem 1rem;
  }
}

.justified {
  text-align: justify;
}

.doreen-text-modules {
  * {
    background-color: white;
  }
}

.doreen-text-special {
  background-color: white;
}
